.business-card {
  width: 300px;
  /* border-radius: 5px; */
  background-color: #f4ece6f0;
  /* background-color: #d5e6fba0; */
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* --highline-color: #0060ff; */
}

.business-card > * {
  margin-top: 1.2rem;
}

.home-page-avatar {
  width: 128px;
  border-radius: 50%;
}

.business-card-name {
  font-size: 2rem;
  font-weight: bold;
}

.business-card-seperator {
  width: 5rem;
  border-bottom: 2px solid #0060ff;
}

.business-card-job-title {
  font-size: 2rem;
}

.business-card-description {
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}

.business-card-contact {
  width: 100%;
  height: 3rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-card-contact > * > img {
  height: 1.5rem;
  width: 3rem;
}
/* @media (max-width: 600px) {
  .home-page-avatar {
    display: flex;
    justify-content: center;
  }

  .home-page-avatar > img {
    width: 30vw;
  }
} */
