.navbar {
  background-color: #ffffff;
  font-weight: bold;
  font-size: large;
  --highline-color: #0060ff;
  --bg-hover-color: #d5e6fb;
  --transition-spped: 400ms;
  --navbar-height: 3rem;
  margin-bottom: 1px;
}

.navbar-main-section {
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 3px -3px var(--highline-color);
}

.navbar-brand {
  display: flex;
  align-items: center;
  align-items: flex-start;
  /* font-family: "Great Vibes", cursive; */
  font-size: 2rem;
  font-weight: bold;
  margin-left: 5%;
}

.navbar-brand > :nth-child(2) {
  color: var(--highline-color);
}

.navbar-brand > :nth-child(3) {
  font-size: 1rem;
}

.navbar-main-section > .navbar-links {
  height: 2.3rem;
  display: flex;
}

.navbar-links > * {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 50px;
  color: #3f3f3f;
  text-decoration: none;
  transition-duration: var(--transition-spped);
  transition-property: background-color, color;
}

.navbar-main-section > .navbar-links > :last-child {
  margin-right: 1rem;
}

.navbar-home-link {
  color: var(--highline-color);
}

/* .navbar-contact-button {
  width: 7rem;
  background-color: #fefefe;
  border: 1px solid var(--highline-color);
  border-radius: 50px;
  padding: 10px;
  color: var(--highline-color);
  font-weight: bold;
  transition-duration: var(--transition-spped);
  transition-property: background-color, color;
} */

.toggle-button {
  position: absolute;
  right: 5%;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: none;
  justify-content: space-around;
  align-items: center;
  color: var(--highline-color);
  cursor: pointer;
  transition-duration: var(--transition-spped);
  transition-property: background-color, color;
}

.navbar-dropdown-menu {
  display: none;
}

@media (hover: hover) {
  .router-link:hover {
    background-color: var(--bg-hover-color);
  }

  .navbar-contact-button:hover {
    background-color: var(--bg-hover-color);
  }

  .toggle-button:hover {
    background-color: var(--bg-hover-color);
  }
}

@media (max-width: 600px) {
  .navbar-main-section > .navbar-links {
    display: none;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-dropdown-menu .navbar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 3px 3px -3px var(--highline-color);
  }

  .navbar-dropdown-menu .navbar-links > * {
    width: 7rem;
    padding: 0.5rem 0;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-around;
  }

  .navbar-dropdown-menu .navbar-links > :last-child {
    margin-bottom: 0.5rem;
  }

  .navbar-dropdown-menu {
    display: block;
  }
}
