.introduction {
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.CV-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  animation: 1s ease-out 0s 1 zoomIn;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 600px) {
  .CV-card {
    flex-direction: column;
  }
}
